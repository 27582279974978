import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { Collapse } from "@mui/material";
import { LogoColor } from "./Logo";
import ProfileNav from "./ProfileNav";
import { drawerWidth } from "./IsDrawerOpen";
import { useAuth } from "../context/AuthContext";
import rateCurrency from "../utils/rateCurrency";
import BoxSidebar from "./Navigation/BoxSidebar";
import { primaryMenu } from "./Navigation";

// ICONS
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Close, Menu, ChevronRight } from "@mui/icons-material";

const numberCommas = (n) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerPc = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Header = ({ isOpenDrawer }, props) => {
  const { userDoc, userBalance, signOutUser } = useAuth();

  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "dark" ? "rgb(10 25 41)" : "#fff";

  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const { window } = props;
  const [dataCurrency, setCurrency] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dekstopOpen, setDekstopOpen] = useState(true);
  const [menuItems, setMenuItems] = useState(primaryMenu);

  useEffect(() => {
    if (userDoc && userDoc.userBlock) {
      signOutUser();
    }
  }, [userDoc, signOutUser]);

  useEffect(() => {
    (async () => {
      const data = await rateCurrency();
      setCurrency(data);
    })();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerDekstop = () => {
    setDekstopOpen(!dekstopOpen);
    isOpenDrawer(!dekstopOpen);
  };

  const handlerMenuToggle = (selected) => () => {
    setMenuItems((items) =>
      items.map((item) => ({
        ...item,
        active: item.name === selected.name && !item.active,
      }))
    );
  };

  const drawer = (
    <Box sx={{ height: "inherit", flexGrow: 1, pt: { xs: 15, sm: 0 } }}>
      <FireNav component="nav" sx={{ height: "inherit" }} disablePadding>
        <ListItemText
          sx={{ py: 3 }}
          primary={<BoxSidebar open={dekstopOpen} />}
          primaryTypographyProps={{
            fontSize: 20,
            fontWeight: "medium",
            letterSpacing: 0,
          }}
        />
        <Divider />
        <Box sx={{ pb: 2, pt: 2 }}>
          {menuItems.map((item) => (
            <div key={item.id}>
              <ListItemButton
                component={item.path === "#" ? "button" : "a"}
                href={item.path === "#" ? null : item.path}
                onClick={handlerMenuToggle(item)}
                sx={{
                  py: 1,
                  minHeight: 32,
                  justifyContent: dekstopOpen ? "initial" : "center",
                  width: "100%",
                }}
              >
                <ListItemIcon
                  sx={{
                    pl: dekstopOpen ? "auto" : 1.5,
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: "medium",
                  }}
                  sx={{ opacity: dekstopOpen ? 1 : 0 }}
                />
                {item.submenu && dekstopOpen && (
                  <>{item.active ? <ExpandLess /> : <ExpandMore />}</>
                )}
              </ListItemButton>
              {item.submenu && (
                <Collapse in={item.active} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.submenu.map((item) => (
                      <ListItemButton
                        key={item.id}
                        component="a"
                        href={item.path}
                        sx={{ ml: 4.5, py: 1, minHeight: 32 }}
                      >
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            fontSize: 14,
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </Box>
        <Divider />
      </FireNav>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const resultCurrency = dataCurrency.filter(
    (currency) => currency.name === userDoc?.currency
  );

  let currentBalance = 0;
  if (resultCurrency[0]?.value) {
    const blcUser = userBalance * resultCurrency[0].value;
    currentBalance = numberCommas(Number.parseFloat(blcUser).toFixed(2));
  } else {
    currentBalance = numberCommas(Number.parseFloat(userBalance).toFixed(2));
  }

  return (
    <>
      <AppBar
        open={matches ? dekstopOpen : false}
        position="fixed"
        className="bg-AppBar-default"
        elevation={0}
      >
        <Box sx={{ px: { xs: 2, sm: 2, md: 3 } }}>
          <Box sx={{ p: 1.5, pb: 0, display: { xs: "block", sm: "none" } }}>
            <LogoColor />
          </Box>
          <Toolbar disableGutters>
            {/* NAV MOBILE */}
            <Box display={{ xs: "flex", sm: "none" }} sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
              >
                {!mobileOpen ? <MenuIcon sx={{ color: 'white' }} /> : <Close sx={{ color: 'white' }} />}
              </IconButton>
            </Box>

            {/* NAV DEKSTOP */}
            <Box display={{ xs: "none", sm: "flex" }} sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerDekstop}
              >
                {!dekstopOpen ? <ChevronRight sx={{ color: 'white' }} /> : <Menu sx={{ color: 'white' }} />}
              </IconButton>
            </Box>

            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <LogoColor sx={{ flexGrow: 1, mr: 2 }} />
            </Box>

            <Box sx={{ flexGrow: 1, mr: 2 }} />

            {/* BALANCE */}
            <Box
              className="bg-balance"
              sx={{
                display: "flex",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="h4"
                color="#fff"
                sx={{ fontSize: { xs: 16, md: 16 }, mr: 2, pt: 0.2 }}
              >
                {userDoc?.currency ? userDoc?.currency : "USD"} {currentBalance}
              </Typography>

              {!userDoc?.userTrading ? (
                <Chip
                  label="Free"
                  color="success"
                  sx={{ fontSize: 12, p: 0, height: 27, mr: 0, borderRadius: 1 }}
                />
              ) : (
                <Chip
                  label="Real"
                  color="success"
                  sx={{ fontSize: 12, p: 0, height: 27, mr: 0, borderRadius: 1 }}
                />
              )}
            </Box>

            {/* PROFILE */}
            <Box
              sx={{
                flexGrow: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProfileNav backgroundColor={backgroundColor} />
            </Box>
          </Toolbar>
        </Box>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* DRAWE MOBILE */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: backgroundColor,
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* DRAWER DEKSTOP */}
        <DrawerPc
          variant="permanent"
          open={dekstopOpen}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              background: backgroundColor,
            },
          }}
        >
          {drawer}
        </DrawerPc>
      </Box>
    </>
  );
};

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
