import React, { useMemo, createContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./assets/App.css";
import "./assets/style.scss";
import RouterUri from "./routes/Router";
import useLocalStorage from "use-local-storage";
import {
  PrimaryColor,
  PrimaryDark,
  SecondaryColor,
  SecondaryDark,
} from "./static/settings";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addLocale(en);

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

function App() {
  // eslint-disable-next-line
  const [mode, setMode] = useLocalStorage("getMode", "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        // eslint-disable-next-line
        setMode((res) => (res === "light" ? "dark" : "light"));
        // setMode(val ? val : "light");
      },
    }),
    [setMode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: PrimaryColor,
            darker: PrimaryDark,
          },
          secondary: {
            main: SecondaryColor,
            darker: SecondaryDark,
          },
          background: {
            default: mode === "light" ? "#f5f5f5" : "rgb(31 45 59)",
          },
          mode,
        },
        shape: {
          pillRadius: 50,
        },
        components: {
          MuiAccordion: {
            styleOverrides: {
              root: {
                borderRadius: "25px",
                "&::before": {
                  height: 0,
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                fontSize: "12px",
                color: "text.primary",
              },
            },
          },
        },
      }),
    [mode]
  );

  // React.useEffect(() => {
  //   setMode("light");
  // }, [setMode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <RouterUri />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
