import React from "react";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "./Button";

const DialogLogout = ({ isLogout, handleClose, signOutUser }) => {
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "dark"
      ? "rgb(10 25 41)"
      : "rgba(255, 255, 255, 0.7)";

  return (
    <Dialog
      open={isLogout}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ backgroundColor: backgroundColor }}
    >
      <DialogTitle
        sx={{ backgroundColor: backgroundColor }}
        id="alert-dialog-title"
      >
        Logout
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: backgroundColor }}>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: backgroundColor }}>
        <Button color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={signOutUser} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLogout;
