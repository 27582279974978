import React from "react";
import { Avatar, Typography, Box, Skeleton } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { PrimaryColor, TextColor } from "../../static/settings";

const BoxSidebar = ({ open }) => {
  const { currentUser, userDoc } = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        component="h2"
        sx={{
          fontSize: 22,
          fontWeight: "bold",
          color: PrimaryColor,
        }}
      >
        Welcome User
      </Typography>

      {userDoc ? (
        <>
          <Avatar
            alt={userDoc?.fullName}
            src={currentUser?.photoURL}
            sx={{ width: !open ? 40 : 60, height: !open ? 40 : 60, m: 2 }}
          />
          <Typography
            component="h2"
            display={{ xs: "flex", sm: !open ? "none" : "flex" }}
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              color: TextColor,
            }}
          >
            {userDoc?.fullName}
          </Typography>
          <Typography
            display={{ xs: "flex", sm: !open ? "none" : "flex" }}
            component="h2"
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              color: TextColor,
            }}
          >
            {`(${userDoc?.userName})`}
          </Typography>
        </>
      ) : (
        <>
          <Skeleton
            variant="rectangular"
            width={62}
            height={62}
            sx={{ borderRadius: 10, mt: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width="50%"
            height={18}
            sx={{ borderRadius: 1, mt: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="50%"
            height={18}
            sx={{ borderRadius: 1, mt: 1 }}
          />
        </>
      )}
    </Box>
  );
};

export default BoxSidebar;
