import * as React from "react";
import Typography from "@mui/material/Typography";
import { TitleApp } from "../static";

const Credit = ({ css }) => {
  return (
    <Typography sx={css} color="#98a0aa" gutterBottom>
      © 2016-{new Date().getFullYear()} {TitleApp}. All Right Reserved.
      {/* - Profitable and reliable investment contracts providing daily payouts on all investment packages. */}
    </Typography>
  );
};

export default Credit;
