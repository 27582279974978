import React, { useContext, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import NoImage from "../../assets/image/avatar/default-pic.jpeg";
import TimeAgo from "../../utils/TimeAgo";

const Message = ({ message }) => {
  const { currentUser } = useAuth();
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`message ${message.senderId === currentUser?.uid && "owner"}`}
    >
      <div className="messageInfo">
        {currentUser?.photoURL ? (
          <img
            src={
              message.senderId === currentUser?.uid
                ? currentUser?.photoURL
                : data.user?.photoURL
            }
            alt="avatar"
          />
        ) : (
          <img src={NoImage} alt="avatar" />
        )}
        {message.date && <TimeAgo timestamp={message.date} />}
      </div>
      <div className="messageContent">
        <p>{message.text}</p>
        {message.img && <img src={message.img} alt="" />}
      </div>
    </div>
  );
};

export default Message;
