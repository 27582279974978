import React from "react";
import Chat from "../component/LiveChat/Chat";

const Chats = () => {
  return (
    <div className="homeChat">
      <div className="containerChat">
        <Chat />
      </div>
    </div>
  );
};

export default Chats;
