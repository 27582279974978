import React, { useContext, useState, useEffect } from "react";
import Attach from "../../assets/image/chat/attach.png";
import { useAuth } from "../../context/AuthContext";
import { ChatContext } from "../../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db, bucket } from "../../utils/Firebase";
import { v4 as uuid } from "uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const Input = () => {
  const uidAdmin = process.env.REACT_APP_UID_ADMIN;

  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [user, setUser] = useState(null);

  const { currentUser, userDoc } = useAuth();
  const { data } = useContext(ChatContext);

  useEffect(() => {
    (async () => {
      const q = query(collection(db, "users"), where("uid", "==", uidAdmin));
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          setUser(doc.data());
        });
      } catch (err) {}
    })();
  }, [uidAdmin]);

  useEffect(() => {
    (async () => {
      const refUserChats = await getDoc(doc(db, "userChats", currentUser?.uid));
      if (!refUserChats.exists()) {
        await setDoc(doc(db, "userChats", currentUser?.uid), {});
      }
      //check whether the group(chats in firestore) exists, if not create
      if (currentUser?.uid && user?.uid) {
        try {
          const combinedId =
            currentUser.uid > user?.uid
              ? `${currentUser?.uid}${user?.uid}`
              : `${user?.uid}${currentUser.uid}`;

          const res = await getDoc(doc(db, "chats", combinedId));
          if (!res.exists()) {
            //create a chat in chats collection
            await setDoc(doc(db, "chats", combinedId), { messages: [] });

            //create user chats
            await updateDoc(doc(db, "userChats", currentUser?.uid), {
              [combinedId + ".userInfo"]: {
                uid: user.uid,
                displayName: user.fullName,
                photoURL: user.photoURL,
              },
              [combinedId + ".date"]: serverTimestamp(),
            });

            await updateDoc(doc(db, "userChats", user.uid), {
              [combinedId + ".userInfo"]: {
                uid: userDoc.uid,
                displayName: userDoc.fullName,
                photoURL: userDoc.photoURL,
              },
              [combinedId + ".date"]: serverTimestamp(),
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [currentUser, userDoc, user]);

  const handleSend = async () => {
    if (img) {
      const storageRef = ref(bucket, uuid());
      const uploadTask = uploadBytesResumable(storageRef, img);
      uploadTask.on(
        (error) => {
          //TODO:Handle Error
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateDoc(doc(db, "chats", data.chatId), {
              messages: arrayUnion({
                id: uuid(),
                text,
                senderId: currentUser?.uid,
                date: Timestamp.now(),
                img: downloadURL,
              }),
            });
          });
        }
      );
    } else {
      await updateDoc(doc(db, "chats", data.chatId), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser?.uid,
          date: Timestamp.now(),
        }),
      });
    }

    await updateDoc(doc(db, "userChats", currentUser.uid), {
      [data.chatId + ".lastMessage"]: {
        text,
      },
      [data.chatId + ".date"]: serverTimestamp(),
    });

    await updateDoc(doc(db, "userChats", data.user.uid), {
      [data.chatId + ".lastMessage"]: {
        text,
      },
      [data.chatId + ".date"]: serverTimestamp(),
    });

    setText("");
    setImg(null);
  };

  return (
    <div className="input">
      <input
        type="text"
        placeholder="Type something..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <div className="send">
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file">
          <img src={Attach} alt="" />
        </label>
        {img && <p>{img.name}</p>}
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;
