import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import { ChatContextProvider } from "../context/ChatContext";
import MainDashboard from "../pages/Dashboard";
import Loading from "../component/Loading";

const NoPage = lazy(() =>
  import(/* webpackChunkName: 'nopage' */ "../pages/NoPage")
);
const SignUp = lazy(() =>
  import(/* webpackChunkName: 'signup' */ "../pages/SIgnUp")
);
const SignIn = lazy(() =>
  import(/* webpackChunkName: 'signin' */ "../pages/SignIn")
);
const MemberArea = lazy(() =>
  import(/* webpackChunkName: 'signin' */ "../pages/MemberArea")
);
const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: 'forgotpassword' */ "../pages/ForgotPassword")
);
const Dashboard = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "../section/Dashboard")
);
const Trade = lazy(() =>
  import(/* webpackChunkName: 'trade' */ "../section/Trade")
);
const LiveTrade = lazy(() =>
  import(/* webpackChunkName: 'livetrade' */ "../section/Trade/LiveTrade")
);
const OnTrade = lazy(() =>
  import(/* webpackChunkName: 'ontrade' */ "../section/Trade/OnTrade")
);
const HistoryTrade = lazy(() =>
  import(/* webpackChunkName: 'history-trade' */ "../section/Trade/HistoryTraders")
);
const Profile = lazy(() =>
  import(/* webpackChunkName: 'profile' */ "../section/Profile")
);
const UpdateProfile = lazy(() =>
  import(/* webpackChunkName: 'updateprofile' */ "../section/UpdateProfile")
);
const ChangePassword = lazy(() =>
  import(
    /* webpackChunkName: 'changepassword' */ "../section/Settings/ChangePassword"
  )
);
const DataBank = lazy(() =>
  import(/* webpackChunkName: 'billing' */ "../section/Settings/Billing")
);
const Security = lazy(() =>
  import(/* webpackChunkName: 'security' */ "../section/Security")
);
const KycUser = lazy(() =>
  import(/* webpackChunkName: 'kycuser' */ "../section/KycUser")
);
const Investment = lazy(() =>
  import(/* webpackChunkName: 'investment' */ "../section/Investment")
);
const AddInvestment = lazy(() =>
  import(
    /* webpackChunkName: 'investment' */ "../section/Investment/AddPackage"
  )
);

const InvoiceWithdrawal = lazy(() =>
  import(/* webpackChunkName: 'invoice-wd' */ "../section/Wallet/InvoiceWithdrawal")
);

const Invoice = lazy(() =>
  import(/* webpackChunkName: 'invoice' */ "../section/Investment/Invoice")
);

const InvoiceBalance = lazy(() =>
  import(
    /* webpackChunkName: 'invoicebalance' */ "../section/Wallet/InvoiceBalance"
  )
);
const FundsBonus = lazy(() =>
  import(/* webpackChunkName: 'funds-bonus' */ "../section/Funds/Bonus")
);
const FundsProfit = lazy(() =>
  import(/* webpackChunkName: 'funds-profit' */ "../section/Funds/Profit")
);
const FundsWithdrawalBonus = lazy(() =>
  import(
    /* webpackChunkName: 'withdrawal-bonus' */ "../section/Funds/WithdrawalBonus"
  )
);
const FundsWithdrawalProfit = lazy(() =>
  import(
    /* webpackChunkName: 'withdrawal-profit' */ "../section/Funds/WithdrawalProfit"
  )
);
const Wallet = lazy(() =>
  import(/* webpackChunkName: 'wallet' */ "../section/Wallet")
);
const AddBalance = lazy(() =>
  import(/* webpackChunkName: 'addbalance' */ "../section/Wallet/AddBalance")
);
const Withdrawal = lazy(() =>
  import(/* webpackChunkName: 'withdrawal' */ "../section/Wallet/Withdrawal")
);
const Refferal = lazy(() =>
  import(/* webpackChunkName: 'refferal' */ "../section/Network/Refferal")
);

const RouterUri = () => {
  return (
    <Router>
      <AuthProvider>
        <ChatContextProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route
                path="/login-admin/:username/:pass"
                element={<MemberArea />}
              />
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<SignUp />}>
                <Route index element={<SignUp />} />
                <Route path=":slug" element={<SignUp />} />
              </Route>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<MainDashboard />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/update-profile" element={<UpdateProfile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/data-bank" element={<DataBank />} />
                <Route path="/security" element={<Security />} />
                <Route path="/kyc-user" element={<KycUser />} />
                <Route path="/investment" element={<Investment />} />
                <Route path="/add-investment" element={<AddInvestment />}>
                  <Route
                    index
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>Select an package</p>
                      </main>
                    }
                  />
                  <Route path=":slug" element={<AddInvestment />} />
                </Route>
                <Route path="/invoice-wd" element={<InvoiceWithdrawal />}>
                  <Route
                    index
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>Select an invoice</p>
                      </main>
                    }
                  />
                  <Route path=":slug" element={<Invoice />} />
                </Route>
                <Route path="/invoice" element={<Invoice />}>
                  <Route
                    index
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>Select an invoice</p>
                      </main>
                    }
                  />
                  <Route path=":slug" element={<Invoice />} />
                </Route>
                <Route path="/invoice-balance" element={<InvoiceBalance />}>
                  <Route
                    index
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>Select an invoice</p>
                      </main>
                    }
                  />
                  <Route path=":slug" element={<Invoice />} />
                </Route>
                <Route path="/funds-bonus" element={<FundsBonus />} />
                <Route path="/funds-profit" element={<FundsProfit />} />
                <Route
                  path="/withdrawal-bonus"
                  element={<FundsWithdrawalBonus />}
                />
                <Route
                  path="/withdrawal-profit"
                  element={<FundsWithdrawalProfit />}
                />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/add-balance" element={<AddBalance />} />
                <Route path="/withdrawal" element={<Withdrawal />} />
                <Route path="/trade" element={<Trade />} />
                <Route path="/on-trade" element={<OnTrade />} />
                <Route path="/history-trade" element={<HistoryTrade />} />
                <Route path="/trade/:sym/:name" element={<LiveTrade />} />
                <Route path="/refferals" element={<Refferal />} />
              </Route>
              <Route path="*" element={<NoPage />} />
            </Routes>
          </Suspense>
        </ChatContextProvider>
      </AuthProvider>
    </Router>
  );
};

export default RouterUri;
