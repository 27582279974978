import React, { useContext, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/Firebase";
import Navbar from "./Navbar";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../context/ChatContext";

const uidAdmin = process.env.REACT_APP_UID_ADMIN;

const Chat = () => {
  const { data, dispatch } = useContext(ChatContext);

  useEffect(() => {
    (async () => {
      const docSnap = await getDoc(doc(db, "users", uidAdmin));
      if (docSnap.exists()) {
        dispatch({ type: "CHANGE_USER", payload: docSnap.data() });
      }
    })();
  }, [dispatch]);

  return (
    <div className="chat">
      <div className="chatInfo">
        <span>{data.user?.fullName}</span>
        <div className="chatIcons">
          <Navbar />
        </div>
      </div>
      <Messages />
      <Input />
    </div>
  );
};

export default Chat;
