import Home from "@mui/icons-material/Home";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import Assessment from "@mui/icons-material/Assessment";
import Settings from "@mui/icons-material/Settings";
import TaskAlt from "@mui/icons-material/TaskAlt";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Lan, Payment, Security } from "@mui/icons-material";

export const primaryMenu = [
  {
    id: 0,
    path: "/",
    icon: <Home />,
    name: "dashboard",
    label: "Dashboard",
    submenu: null,
    active: false,
  },
  {
    id: 1,
    path: "#",
    icon: <Settings />,
    name: "settings",
    label: "Settings",
    submenu: [
      {
        id: 0,
        path: "/profile",
        icon: null,
        name: "profile",
        label: "Profile",
      },
      {
        id: 1,
        path: "/data-bank",
        icon: null,
        name: "data-bank",
        label: "Bank",
      },
      {
        id: 2,
        path: "/change-password",
        icon: null,
        name: "change-password",
        label: "Password",
      },
    ],
    active: false,
  },
  {
    id: 2,
    path: "/security",
    icon: <Security />,
    name: "security",
    label: "Security",
    submenu: null,
    active: false,
  },
  {
    id: 3,
    path: "/kyc-user",
    icon: <AssignmentInd />,
    name: "kyc",
    label: "KYC",
    submenu: null,
    active: false,
  },
  {
    id: 4,
    path: "/investment",
    icon: <TaskAlt />,
    name: "investment",
    label: "Investment",
    submenu: null,
    active: false,
  },
  {
    id: 5,
    path: "#",
    icon: <CandlestickChartIcon />,
    name: "trade",
    label: "Trade",
    submenu: [
      {
        id: 0,
        path: "/trade/BTC/bitcoin",
        icon: null,
        name: "trade",
        label: "Trade",
      },
      {
        id: 1,
        path: "/on-trade",
        icon: null,
        name: "on-trade",
        label: "On Trade",
      },
      {
        id: 2,
        path: "/history-trade",
        icon: null,
        name: "history-trade",
        label: "History Trade",
      },
    ],
    active: false,
  },
  {
    id: 6,
    path: "#",
    icon: <Payment />,
    name: "funds",
    label: "Funds",
    submenu: [
      {
        id: 0,
        path: "/funds-bonus",
        icon: null,
        name: "bonus",
        label: "Bonus",
      },
      {
        id: 1,
        path: "/funds-profit",
        icon: null,
        name: "profit",
        label: "Profit",
      },
      {
        id: 2,
        path: "/withdrawal-bonus",
        icon: null,
        name: "withdrawal-bonus",
        label: "Withdrawal Bonus",
      },
      {
        id: 3,
        path: "/withdrawal-profit",
        icon: null,
        name: "withdrawal-profit",
        label: "Withdrawal Profit",
      },
    ],
    active: false,
  },
  {
    id: 7,
    path: "#",
    icon: <AccountBalanceWalletIcon />,
    name: "wallet",
    label: "Wallet",
    submenu: [
      {
        id: 0,
        path: "/wallet",
        icon: null,
        name: "balance",
        label: "Balance",
      },
      {
        id: 1,
        path: "/add-balance",
        icon: null,
        name: "add-balance",
        label: "Add Balance",
      },
      // {
      //   id: 2,
      //   path: "/transfer-balance",
      //   icon: null,
      //   name: "transfer-balance",
      //   label: "Transfer",
      // },
      {
        id: 3,
        path: "/withdrawal",
        icon: null,
        name: "withdrawal",
        label: "Withdrawal",
      },
    ],
    active: false,
  },
  {
    id: 8,
    path: "#",
    icon: <Lan />,
    name: "network",
    label: "Network",
    submenu: [
      {
        id: 0,
        path: "/refferals",
        icon: null,
        name: "refferals",
        label: "Refferals",
      },
      // {
      //   id: 1,
      //   path: "/generation",
      //   icon: null,
      //   name: "generation",
      //   label: "Generation",
      // },
    ],
    active: false,
  },
  // {
  //   id: 9,
  //   path: "/register",
  //   icon: <GroupAdd />,
  //   name: "register",
  //   label: "Register",
  //   submenu: null,
  //   active: false,
  // },
  // {
  //   id: 10,
  //   path: "/logout",
  //   icon: <PowerSettingsNewIcon />,
  //   name: "logout",
  //   label: "Logout",
  //   submenu: null,
  //   active: false,
  // },
  // {
  //   id: 2,
  //   path: "/account",
  //   icon: <AssignmentInd />,
  //   name: "account",
  //   label: "Account",
  //   submenu: null,
  //   active: false,
  // },
  // {
  // {
  //   id: 5,
  //   path: "/settings",
  //   icon: <Settings />,
  //   name: "settings",
  //   label: "Settings",
  //   submenu: null,
  //   active: false,
  // },
];
