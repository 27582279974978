import React from "react";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="user">
        <span>Live Chat</span>
      </div>
    </div>
  );
};

export default Navbar;
