import React from 'react';
import { TickerTape } from "react-tradingview-embed";

const WidgetTicker = ({isDark}) => <TickerTape widgetProps={{
  "symbols": [
    {
      "description": "",
      "proName": "BINANCE:BTCUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:ETHUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:SOLUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:LINKUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:XRPUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:ADAUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:ADAUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:AVAXUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:MATICUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:BNBUSDT"
    },
    {
      "description": "",
      "proName": "BINANCE:DOGEUSDT"
    },
    {
      "description": "", 
      "proName": "BINANCE:FTMUSDT"
    }
  ],
  "showSymbolLogo": true,
  "isTransparent": false,
  "displayMode": "compact",
  "colorTheme": isDark==="dark" ? "dark" : "light",
  "locale": "en"
  }} />

export default WidgetTicker;
