import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Credit from "./Credit";
import { drawerWidth } from "./IsDrawerOpen";

export default function Footer() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        ml: { sm: `${drawerWidth}px` },
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <Divider variant="middle" sx={{ mt: 3, mb: 3, mr: 0, ml: 0 }} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={6}>
          <Credit
            css={{ fontSize: 14, textAlign: { xs: "center", md: "left" } }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
