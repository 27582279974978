import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { AccountCircle, Logout, Key } from "@mui/icons-material";
import { useAuth } from "../context/AuthContext";
import DialogLogout from "./DialogLogout";

const ProfileNav = ({ backgroundColor }) => {
  const [isLogout, setOpenLogout] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { currentUser, signOutUser, userDoc } = useAuth();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      id: 1,
      name: "Account",
      icon: <AccountCircle fontSize="small" />,
      onclick: () => {
        setAnchorElUser(null);
        window.location.href = "/profile";
      },
    },
    // {
    //   id: 2,
    //   name: "Change Currency",
    //   icon: <CurrencyExchange fontSize="small" />,
    //   onclick: () => {
    //     setAnchorElUser(null);
    //     window.location.href = "/change-currency";
    //   },
    // },
    {
      id: 3,
      name: "Change Password",
      icon: <Key fontSize="small" />,
      onclick: () => {
        setAnchorElUser(null);
        window.location.href = "/change-password";
      },
    },
    {
      id: 4,
      name: "Logout",
      icon: <Logout fontSize="small" />,
      onclick: () => {
        setAnchorElUser(null);
        handleClickOpen();
      },
    },
  ];

  const handleClickOpen = () => {
    setOpenLogout(true);
  };

  const handleClose = () => {
    setOpenLogout(false);
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {!userDoc ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar alt={userDoc?.firstName} src={currentUser?.photoURL} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          "aria-labelledby": "basic-menu",
          sx: { background: backgroundColor },
        }}
        PaperProps={{
          style: {
            width: "25ch",
          },
        }}
      >
        <MenuItem>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt={userDoc?.fullName}
              src={currentUser?.photoURL}
              sx={{ width: 50, height: 50 }}
            />
          </IconButton>
          <Box sx={{ ml: 1.5, display: "flex", flexDirection: "column" }}>
            <Typography
              textAlign="left"
              variant="h6"
              component="h6"
              sx={{ fontSize: 16, fontWeight: 500 }}
            >
              {userDoc?.fullName}
            </Typography>
            <Typography
              textAlign="left"
              color="text.secondary"
              sx={{ fontSize: 12, fontWeight: 400 }}
            >
              ({userDoc?.userName})
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        {settings.map((setting) => (
          <Box key={setting.id}>
            <MenuItem onClick={setting.onclick}>
              <ListItemIcon>{setting.icon}</ListItemIcon>
              <Typography textAlign="center">{setting.name}</Typography>
            </MenuItem>
            {setting.id === 3 ? <Divider /> : null}
          </Box>
        ))}
      </Menu>
      <DialogLogout
        isLogout={isLogout}
        handleClose={handleClose}
        signOutUser={signOutUser}
      />
    </>
  );
};

export default ProfileNav;
