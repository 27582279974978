import { createContext, useContext, useEffect, useState } from "react";
import { db, auth } from "../utils/Firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isMenuContact, setMenuContact] = useState(false);
  const [rateCurrency, setRateCurrency] = useState([]);
  const [userDoc, setUserDoc] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function getUser() {
    return auth.currentUser;
  }

  function signOutUser() {
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        //logged in
        if (user?.uid && user?.emailVerified) {
          (async () => {
            const docData = doc(db, "users", user?.uid);
            const docSnap = await getDoc(docData);
            if (docSnap.exists()) {
              setUserDoc(docSnap.data());
            } else {
              console.log("No such document!");
            }
          })();
          (async () => {
            const docBalance = doc(db, "balances", user?.uid);
            onSnapshot(docBalance, (doc) => {
              if (doc.data()) setUserBalance(doc.data().balance);
            });
          })();
          (async () => {
            const docsettings = doc(db, "settings", "y9BCYZ9XCXrMbQC9fbzD");
            const docSnapSet = await getDoc(docsettings);
            // await updateDoc(docsettings, data);
            if (docSnapSet.exists()) {
              // console.log(docSnapSet.data());
              setMenuContact(docSnapSet.data().menuContact);
              setRateCurrency([
                {
                  name: "MYR",
                  value: docSnapSet.data().rateMYR,
                },
                {
                  name: "IDR",
                  value: docSnapSet.data().rateIDR,
                },
                {
                  name: "SGD",
                  value: docSnapSet.data().rateSGD,
                },
                {
                  name: "BND",
                  value: docSnapSet.data().rateBND,
                },
              ]);
            }
          })();
          setCurrentUser(user);
        }
      } else {
        //logged out
        setCurrentUser(user);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userDoc,
    userBalance,
    isMenuContact,
    rateCurrency,
    getUser,
    signOutUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
