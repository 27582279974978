import { db } from "./Firebase";
import { doc, getDoc } from "firebase/firestore";

const rateCurrency = async () => {
  let data = [];
  const docCurrency = doc(db, "settings", "y9BCYZ9XCXrMbQC9fbzD");
  const docSnap = await getDoc(docCurrency);
  if (docSnap.exists()) {
    data = [
      {
        name: "MYR",
        value: docSnap.data().rateMYR,
      },
      {
        name: "IDR",
        value: docSnap.data().rateIDR,
      },
      {
        name: "SGD",
        value: docSnap.data().rateSGD,
      },
      {
        name: "BND",
        value: docSnap.data().rateBND,
      },
    ];
  }
  return data;
};

export default rateCurrency;
