import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export const LogoColor = ({ display }) => {
  return (
    <Box sx={{ flexGrow: 1, mr: 2, display: display }}>
      <Link
        component="a"
        href="/"
        underline="none"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box component="img" src="/logo.svg" sx={{ height: 40 }} />
      </Link>
    </Box>
  );
};

export const LogoGrey = () => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Link
        component="a"
        href="/"
        underline="none"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          component="img"
          src="/logo.svg"
          sx={{ height: { xs: 50, md: 60 }, mr: 1 }}
        />
      </Link>
    </Box>
  );
};

export const LogoOnly = ({ display }) => {
  return (
    <Box sx={{ flexGrow: 1, mr: 2 }}>
      <Link component="a" href="/" underline="none" sx={{ display: display }}>
        <Box
          component="img"
          src="/logo.svg"
          sx={{ height: { xs: 30, md: 50 }, mr: 1 }}
        />
      </Link>
    </Box>
  );
};
