import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useAuth } from "../context/AuthContext";
import FooterApp from "../component/Footer";
import Header from "../component/Header";
import { drawerWidth } from "../component/IsDrawerOpen";
import { ChatBubble, Close } from "@mui/icons-material";
import { Box, Fab } from "@mui/material";
import Chats from "../section/Chat";
import WidgetTicker from "../component/WidgetTicker";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth - 70}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Footer = styled("footer", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth - 70}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  minHeight: useMediaQuery(theme.breakpoints.up("sm")) ? "65px" : "40px",
}));

const Dashboard = () => {
  const { currentUser, userDoc, signOutUser, isMenuContact } = useAuth();

  const [isOpenDrawer, setOpenDrawer] = useState(true);
  const [isOpenChat, setOpenChat] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const chartMode = theme.palette.mode;

  if (userDoc?.userStatus === "banned") {
    signOutUser();
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const ContentChat = () => {
    return (
      <Box
        display={isOpenChat ? "block" : "none"}
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(3),
        }}
      >
        <Chats />
      </Box>
    );
  };

  const ButtonChat = () => {
    return (
      <Fab
        color="primary"
        aria-label="chat"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(3),
          right: (theme) => theme.spacing(3),
        }}
        onClick={() => setOpenChat(!isOpenChat)}
      >
        {!isOpenChat ? <ChatBubble /> : <Close />}
      </Fab>
    );
  };

  return (
    <>
      <Header isOpenDrawer={(el) => setOpenDrawer(el)} />
      <Main open={isOpenDrawer}>
        <DrawerHeader />
        <Box sx={{marginTop: matches ? "0" : "65px"}}>
          <WidgetTicker isDark={chartMode} />
        </Box>
        <Outlet />
        {isMenuContact && (
          <>
            <ContentChat />
            <ButtonChat />
          </>
        )}
      </Main>
      <Footer open={isOpenDrawer}>
        <FooterApp />
      </Footer>
    </>
  );
};

export default Dashboard;
